<template>
  <div>
    <section class="pt-5 mb-4">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 mx-auto">
            <div class="row aiz-steps arrow-divider">
              <div class="col " :class="step > i + 1 ? 'done' : (step == i + 1 ? 'active' : '')" v-for="(item, i) in tabList" :key="i">
                <div class="text-center" :class="step > i + 1 ? 'text-success' : (step == i + 1 ? 'text-primary' : '')">
                  <i class="la-3x mb-2 las "  :class="[step < i + 1 ? 'opacity-50' : '', item.icon]"></i>
                  <h3 class="fs-14 fw-600 d-none d-lg-block " :class="step < i + 1 ? 'opacity-50' : ''">{{i + 1}}.{{item.title}}</h3>
                </div>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mb-4" id="cart-summary" v-if="step == 1">
      <div class="container">
        <div class="row" v-if="cartData.length > 0">
          <div class="col-xxl-8 col-xl-10 mx-auto">
            <div class="shadow-sm bg-white p-3 p-lg-4 rounded text-left">
              <div class="mb-4">
                <div class="row gutters-5 d-none d-lg-flex border-bottom mb-3 pb-3">
                    <div class="col-md-5 fw-600">{{ $t('chan-pin') }}</div>
                    <div class="col fw-600">{{ $t('jia-ge') }}</div>
                    <div class="col fw-600">{{ $t('shui-fei') }}</div>
                    <div class="col fw-600">{{ $t('shu-liang-0') }}</div>
                    <div class="col fw-600">{{ $t('zong-jia-0') }}</div>
                    <div class="col-auto fw-600">{{ $t('shan-chu') }}</div>
                </div>
                <ul class="list-group list-group-flush">
                  <li class="list-group-item px-0 px-lg-3" v-for="(item, i) in cartData" :key="i">
                    <div class="row gutters-5">
                      <div class="col-lg-5 d-flex">
                        <span class="mr-2 ml-0">
                          <img :src="item.Goods.ImgUrl" class="img-fit size-60px rounded">
                        </span>
                        <span class="fs-14 opacity-60">{{ item.Goods.GoodsName }}</span>
                      </div>

                      <div class="col-lg col-4 order-1 order-lg-0 my-3 my-lg-0">
                        <span class="opacity-60 fs-12 d-block d-lg-none">{{ $t('jia-ge') }}</span>
                        <span class="fw-600 fs-16">{{ resetPrice(skuPrice(item)) }}</span>
                      </div>
                      <div class="col-lg col-4 order-2 order-lg-0 my-3 my-lg-0">
                          <span
                              class="opacity-60 fs-12 d-block d-lg-none">{{ $t('shui-fei-0') }}</span>
                          <span
                              class="fw-600 fs-16">{{ resetPrice(item.tax) }}</span>
                      </div>

                      <div class="col-lg col-6 order-4 order-lg-0">
                        <div class="row no-gutters align-items-center aiz-plus-minus mr-2 ml-0">
                          <button  class="btn col-auto btn-icon btn-sm btn-circle btn-light" type="button" data-type="minus" @click="minusCount(item,i)">
                            <i class="las la-minus"></i>
                          </button>
                          <input type="number"  class="col border-0 text-center flex-grow-1 fs-16 input-number" v-model="item.count"    >
                          <button  class="btn col-auto btn-icon btn-sm btn-circle btn-light" type="button" data-type="plus" @click="plusCount(item,i)">
                            <i class="las la-plus"></i>
                          </button>
                        </div>
                        <!-- <span class="fw-600 fs-16">1</span> -->
                      </div>
                      <div class="col-lg col-4 order-3 order-lg-0 my-3 my-lg-0">
                        <span  class="opacity-60 fs-12 d-block d-lg-none">{{ $t('zong-jia-1') }}</span>
                        <span  class="fw-600 fs-16 text-primary">{{ resetPrice(getTotal(item)) }}</span>
                      </div>
                      <div class="col-lg-auto col-6 order-5 order-lg-0 text-right">
                        <a href="javascript:void(0)"  class="btn btn-icon btn-sm btn-soft-primary btn-circle" @click="removeGoods(item)">
                          <i class="las la-trash"></i>
                        </a>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>

              <div class="px-3 py-2 mb-4 border-top d-flex justify-content-between">
                  <span class="opacity-60 fs-15">{{ $t('xiao-ji') }}</span>
                  <span class="fw-600 fs-17">{{ resetPrice(getSum()) }}</span>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6 text-center text-md-left order-1 order-md-0">
                  <a  class="btn btn-link" @click="goBack">
                    <i class="las la-arrow-left"></i>
                    {{ $t('fan-hui-dian-pu') }} </a>
                </div>
                <div class="col-md-6 text-center text-md-right">
                  <button class="btn btn-primary fw-600" @click="doSubmit">{{ $t('ji-xu-fa-huo') }}</button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row" v-else>
          <div class="col-xl-8 mx-auto">
            <div class="shadow-sm bg-white p-4 rounded">
              <div class="text-center p-3">
                <i class="las la-frown la-3x opacity-60 mb-3"></i>
                <h3 class="h4 fw-700">{{ $t('nin-de-gou-wu-che-shi-kong-de') }}</h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="mb-4 gry-bg" v-if="step == 2">
      <div class="container">
        <div class="row cols-xs-space cols-sm-space cols-md-space">
          <div class="col-xxl-8 col-xl-10 mx-auto">
            <form class="form-default">
              <div class="shadow-sm bg-white p-4 rounded mb-4">
                <div class="row gutters-5">
                  <div class="col-md-6 mb-3" v-for="(item, i) in addressList" :key="i">
                    <label class="aiz-megabox d-block bg-white mb-0">
                      <input type="radio" name="address_id" :checked="item.IsSelected == 1" required @click="changeAddress(item)">
                      <span class="d-flex p-3 aiz-megabox-elem">
                        <span class="aiz-rounded-check flex-shrink-0 mt-1"></span>
                        <span class="flex-grow-1 pl-3 text-left">
                          <div>
                              <span class="opacity-60">{{ $t('di-zhi-1') }}</span>
                              <span class="fw-600 ml-2">{{ item.Address }}</span>
                          </div>
                          <div>
                              <span class="opacity-60">{{ $t('you-zheng-bian-ma') }}</span>
                              <span class="fw-600 ml-2">{{ item.ZipCode }}</span>
                          </div>
                          <div>
                              <span class="opacity-60">{{ $t('cheng-shi-0') }}</span>
                              <span class="fw-600 ml-2">{{ item.City }}</span>
                          </div>
                          <div>
                              <span class="opacity-60">{{ $t('sheng-fen-1') }}</span>
                              <span class="fw-600 ml-2">{{ item.Province }}</span>
                          </div>
                          <div>
                              <span class="opacity-60">{{ $t('guo-jia-0') }}</span>
                              <span class="fw-600 ml-2">{{ item.Country }}</span>
                          </div>
                          <div>
                              <span class="opacity-60">{{ $t('quan-ming-1') }}</span>
                              <span class="fw-600 ml-2">{{ item.FullName }}</span>
                          </div>
                          <div>
                              <span class="opacity-60">{{ $t('dian-hua-1') }}</span>
                              <span class="fw-600 ml-2">{{ item.Phone }}</span>
                          </div>
                      </span>
                      </span>
                    </label>
                    <div class="dropdown position-absolute right-0 top-0">
                      <Popover v-model="item.showAction">
                        <div class="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton" @click="editAddress(i)">
                          <a class="dropdown-item" >
                            {{ $t('xiu-gai') }} </a>
                        </div>
                        <template #reference>
                          <button class="btn bg-gray px-2" type="button" data-toggle="dropdown">
                            <i class="la la-ellipsis-v"></i>
                          </button>
                        </template>
                      </Popover>
                      
                    </div>
                  </div>
                  <div class="col-md-6 mx-auto mb-3" >
                    <div class="border p-3 rounded mb-3 c-pointer text-center bg-white h-100 d-flex flex-column justify-content-center" @click="addAddress">
                      <i class="las la-plus la-2x mb-3"></i>
                      <div class="alpha-7">{{ $t('tian-jia-xin-di-zhi') }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row align-items-center">
                <div class="col-md-6 text-center text-md-left order-1 order-md-0">
                  <a class="btn btn-link" @click="step -=1">
                    <i class="las la-arrow-left"></i>
                    {{ $t('fan-hui-dian-pu-0') }} </a>
                </div>
                <div class="col-md-6 text-center text-md-right">
                  <button type="submit" class="btn btn-primary fw-600" @click="doSubmit">{{ $t('ji-xu-jiao-huo-xin-xi') }}</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section class="py-4 gry-bg" v-if="step == 3">
      <div class="container">
        <div class="row">
          <div class="col-xxl-8 col-xl-10 mx-auto">
            <form class="form-default">
              <div class="card mb-3 shadow-sm border-0 rounded">
                <div class="card-header p-3">
                  <h5 class="fs-16 fw-600 mb-0"> {{ $t('chan-pin') }}</h5>
                </div>
                <div class="card-body">
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item" v-for="(item, i) in cartData" :key="i">
                      <div class="d-flex">
                        <span class="mr-2">
                          <img :src="item.Goods.ImgUrl" class="img-fit size-60px rounded">
                        </span>
                        <span class="fs-14 opacity-60">{{ item.Goods.GoodsName }}</span>
                      </div>
                    </li>
                  </ul>
                  
                  <div class="row border-top pt-3">
                    <div class="col-md-9">
                      <h6 class="fs-15 fw-600">{{ $t('xuan-ze-jiao-huo-lei-xing') }}</h6>
                    </div>
                    <div class="col-md-3">
                      <div class="row gutters-5">
                        <div class="col-12">
                          <label class="aiz-megabox d-block bg-white mb-0">
                            <input type="radio"  name="shipping_type" value="1" checked @change="changePickType(1)">
                            <span class="d-flex p-3 aiz-megabox-elem">
                              <span class="aiz-rounded-check flex-shrink-0 mt-1"></span>
                              <span class="flex-grow-1 pl-3 fw-600">{{ $t('song-huo-shang-men') }}</span>
                            </span>
                          </label>
                        </div>
                        <!-- <div class="col-6">
                          <label class="aiz-megabox d-block bg-white mb-0">
                            <input type="radio"  name="shipping_type"  value="2" @change="changePickType(2)">
                            <span class="d-flex p-3 aiz-megabox-elem">
                              <span class="aiz-rounded-check flex-shrink-0 mt-1"></span>
                              <span class="flex-grow-1 pl-3 fw-600">本地取货</span>
                            </span>
                          </label>
                        </div> -->
                      </div>
                      <!-- <div class="mt-4 pickup_point_id_admin " :class="pickType == 2 ? '' : 'd-none'">
                        <a-select class="form-control aiz-selectpicker clear" size="large" placeholder="选择站点">
                          <a-select-option value="1">站点1-地址1-电话1</a-select-option>
                          <a-select-option value="2">站点2-地址2-电话2</a-select-option>
                        </a-select>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>

              <div class="pt-4 d-flex justify-content-between align-items-center">
                <a  @click="step -=1">
                  <i class="la la-angle-left"></i>
                  {{ $t('fan-hui-dian-pu-1') }} </a>
                <button type="submit" class="btn fw-600 btn-primary" @click="doSubmit">{{ $t('ji-xu-fu-kuan') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>

    <section class="mb-4" v-if="step == 4">
      <div class="container text-left">
        <div class="row">
          <div class="col-lg-8">
            <form  class="form-default" >
              <div class="card rounded border-0 shadow-sm">
                <div class="card-header p-3">
                  <h3 class="fs-16 fw-600 mb-0">
                    {{ $t('ren-he-qi-ta-xin-xi') }} </h3>
                </div>
                <div class="form-group px-3 pt-3">
                  <textarea name="additional_info" rows="5" class="form-control" key="" :placeholder="$t('shu-ru-nin-de-wen-zi')"></textarea>
                </div>

<!--                选择付款方式-->
                <div class="card-header p-3" v-show="false">
                  <h3 class="fs-16 fw-600 mb-0">
                    {{ $t('xuan-ze-fu-kuan-fang-shi') }} </h3>
                </div>
                <div class="card-body text-center">
                  <div class="row">
                    <div class="col-xxl-8 col-xl-10 mx-auto">
                      <div class="row gutters-10">
                        <div class="col-6 col-md-4" v-for="(item, i) in payList" :key="i">
                          <label class="aiz-megabox d-block mb-3">
                            <input type="radio" name="payment_option" :checked="payIndex == i" @change="changeType(i)">
                            <span class="d-block aiz-megabox-elem p-3">
                              <img :src="item.url"  class="img-fluid mb-2">
                              <span class="d-block text-center">
                                <span class="d-block fw-600 fs-15">{{ item.name }}</span>
                              </span>
                            </span>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class=" mb-3 rounded border bg-white p-3 text-left" :class="showInfo ? '' : 'd-none'">
                    <div id="manual_payment_description">
                      <h5>{{payInfo.name}}: {{payInfo.address}}</h5>
                    </div>
                  </div>
                  <div class="separator mb-3" v-show="false">
                    <span class="bg-white px-3">
                      <span class="opacity-60">{{ $t('huo-zhe') }}</span>
                    </span>
                  </div>
                  <div class="py-4 text-center">
                    <div class="h6 mb-3">
                      <span class="opacity-80">{{ $t('nin-de-qian-bao-yue') }}</span>
                      <span class="fw-600">{{resetPrice(userMoney)}}</span>
                    </div>
                      <button type="button" class="btn btn-secondary" disabled v-if="checkMoney()">
                        {{ $t('yuebu-zu') }} </button>
                      <button type="button"  class="btn btn-primary fw-600" v-else @click="doSubmit">
                        {{ $t('shi-yong-qian-bao-zhi-fu') }} </button>
                  </div>
                </div>
              </div>
                <div class="pt-3">
                  <label class="aiz-checkbox">
                    <input type="checkbox" required id="agree_checkbox" checked>
                    <span class="aiz-square-check"></span>
                    <span>{{ $t('wo-tong-yi') }}</span>
                  </label>
                  <a href="javascript:void(0)">{{ $t('tiao-kuan-he-tiao-jian') }}</a>,
                  <a href="javascript:void(0)">{{ $t('tui-huo-zheng-ce') }}</a> 
                  <!-- & -->
                  <a href="javascript:void(0)">{{ $t('yin-si-zheng-ce') }}</a>
                </div>

                <div class="row align-items-center pt-3">
                  <div class="col-6">
                    <a  class="link link--style-3" @click="step -=1">
                      <i class="las la-arrow-left"></i>
                      {{ $t('fan-hui-dian-pu-2') }} </a>
                  </div>
                  <div class="col-6 text-right" v-show="false">
                    <button type="button" class="btn btn-primary fw-600" @click="doSubmit">{{ $t('wan-cheng-ding-dan') }}</button>
                  </div>
                </div>
            </form>
          </div>

          <div class="col-lg-4 mt-lg-0 mt-4" id="cart_summary">
            <div class="card rounded border-0 shadow-sm">
              <div class="card-header">
                <h3 class="fs-16 fw-600 mb-0">{{ $t('zhai-yao') }}</h3>
                <div class="text-right">
                  <span class="badge badge-inline badge-primary">
                    {{ $t('1-ge-xiang-mu') }} </span>
                </div>
              </div>

              <div class="card-body">
                <!-- <div class="bg-soft-primary border-soft-primary mb-2 rounded border px-2">
                  俱乐部积分:
                  <span class="fw-700 float-right">100</span>
                </div> -->
                <table class="table">
                  <thead>
                    <tr>
                      <th class="product-name">{{ $t('chan-pin') }}</th>
                      <th class="product-total text-right">{{ $t('zong-ji') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="cart_item" v-for="(item, i) in cartData" :key="i">
                      <td class="product-name">
                        {{ item.Goods.GoodsName }}
                        <strong class="product-quantity">
                          × {{ item.count }}
                        </strong>
                      </td>
                      <td class="product-total text-right">
                        <span class="pl-4 pr-0">{{resetPrice(getTotal(item))}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <table class="table">
                  <tfoot>
                    <tr class="cart-subtotal">
                      <th>{{ $t('xiao-ji-0') }}</th>
                      <td class="text-right">
                        <span class="fw-600">{{resetPrice(getSum())}}</span>
                      </td>
                    </tr>

                    <tr class="cart-shipping">
                      <th>{{ $t('shui') }}</th>
                      <td class="text-right">
                        <span class="font-italic">{{resetPrice(0.00)}}</span>
                      </td>
                    </tr>

                    <tr class="cart-shipping">
                      <th>{{ $t('zong-yun-fei') }}</th>
                      <td class="text-right">
                        <span class="font-italic">{{resetPrice(0.00)}}</span>
                      </td>
                    </tr>

                    <!-- <tr class="cart-shipping">
                      <th>兑换积分</th>
                      <td class="text-right">
                        <span class="font-italic">100</span>
                      </td>
                    </tr> -->

                    <tr class="cart-shipping">
                      <th>{{ $t('you-hui-quan-zhe-kou') }}</th>
                      <td class="text-right">
                        <span class="font-italic">{{resetPrice(0.00)}}</span>
                      </td>
                    </tr>

                    <tr class="cart-total">
                        <th><span class="strong-600">{{ $t('zong-ji-0') }}</span></th>
                        <td class="text-right">
                            <strong><span>{{resetPrice(getSum())}}</span></strong>
                        </td>
                    </tr>
                  </tfoot>
                </table>

                <!-- <div class="mt-3">
                  <form class="" >
                    <div class="input-group">
                      <div class="form-control">积分: 100</div>
                      <div class="input-group-append">
                          <button type="submit"  class="btn btn-primary">删除积分</button>
                      </div>
                    </div>
                  </form>
                </div> -->

                <div class="mt-3" v-if="couponCode">
                  <form class="">
                    <div class="input-group">
                      <div class="form-control">{{couponCode}}</div>
                      <div class="input-group-append">
                          <button type="button" id="coupon-remove" class="btn btn-primary">{{ $t('geng-gai-you-hui-quan') }}</button>
                      </div>
                    </div>
                  </form>
                </div>

                <div class="mt-3" v-if="!couponCode">
                  <form class="">
                    <div class="input-group">
                      <input type="text" class="form-control" name="code"   :placeholder="$t('you-you-hui-quan-ma-zai-zhe-li-shu-ru')" required>
                      <div class="input-group-append">
                        <button type="button" id="coupon-apply"  class="btn btn-primary">{{ $t('shen-qing') }}</button>
                      </div>
                    </div>
                  </form>
                </div>

              </div>
            </div>  
          </div>
        </div>
      </div>
    </section>

    <section class="py-4" v-if="step == 5">
      <div class="container text-left">
        <div class="row">
          <div class="col-xl-8 mx-auto">
            <div class="text-center py-4 mb-4">
              <i class="la la-check-circle la-3x text-success mb-3"></i>
              <h1 class="h3 mb-3 fw-600">{{ $t('xie-xie-nin-de-ding-dan') }}</h1>
              <p class="opacity-70 font-italic">{{ $t('fu-ben-huo-nin-de-ding-dan-zhai-yao-yi-fa-song-zhi') }} {{userInfo.Email}}</p>
            </div>
            <div class="mb-4 bg-white p-4 rounded shadow-sm">
              <h5 class="fw-600 mb-3 fs-17 pb-2">{{ $t('ding-dan-zhai-yao') }}</h5>
              <div class="row">
                <div class="col-md-6">
                  <table class="table">
                    <tr>
                      <td class="w-50 fw-600">{{ $t('ding-gou-ri-qi') }}</td>
                      <td>{{getTime()}}</td>
                    </tr>
                    <tr>
                      <td class="w-50 fw-600">{{ $t('xing-ming') }}</td>
                      <td>{{addressInfo.FullName}}</td>
                    </tr>
                    <tr>
                      <td class="w-50 fw-600">{{ $t('dian-hua-1') }}</td>
                      <td>{{addressInfo.Phone}}</td>
                    </tr>
                    <tr>
                      <td class="w-50 fw-600">{{ $t('shou-huo-di-zhi-1') }}</td>
                      <td>{{addressInfo.Address}} {{addressInfo.City}} {{addressInfo.Country}}</td>
                    </tr>
                  </table>
                </div>
                <div class="col-md-6">
                  <table class="table">
                    <tr>
                      <td class="w-50 fw-600">{{ $t('ding-dan-zhuang-tai-0') }}</td>
                      <td>{{ $t('chu-li-zhong') }}</td>
                    </tr>
                    <tr>
                      <td class="w-50 fw-600">{{ $t('ding-dan-jine') }}</td>
                      <td>{{resetPrice(Total)}}</td>
                    </tr>
                    <tr>
                      <td class="w-50 fw-600">{{ $t('song-huo-di-zhi') }}</td>
                      <td>{{addressInfo.Address}} {{addressInfo.City}} {{addressInfo.Country}}</td>
                    </tr>
                    <tr>
                      <td class="w-50 fw-600">{{ $t('fu-kuan-fang-shi-0') }}</td>
                      <td>{{this.payIndex == 0 ? $t('huo-dao-fu-kuan') : 'USDT-TRC20'}}</td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="card shadow-sm border-0 rounded" >
              <div class="card-body">
                <div class="text-center py-4 mb-4">
<!--                  <h2 class="h5">{{ $t('ding-dan-bian-hao-1') }} <span class="fw-700 text-primary">1239810238109283</span></h2>-->
                </div>
                <div>
                  <h5 class="fw-600 mb-3 fs-17 pb-2">{{ $t('ding-dan-xiang-qing') }}</h5>
                  <div>
                    <table class="table table-responsive-md">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th width="30%">{{ $t('chan-pin') }}</th>
                          <th>{{ $t('shu-liang-0') }}</th>
                          <th>{{ $t('jiao-huo-lei-xing') }}</th>
                          <th class="text-right">{{ $t('jia-ge') }}</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(item, i) in cartData" :key="i">
                          <td>{{ i + 1 }}</td>
                            <td>
                              <a class="text-reset">
                                  {{ item.Goods.GoodsName }}
                              </a>
                            </td>
                            <td>
                              {{item.count}}
                            </td>
                            <td>
                              {{ $t('song-huo-shang-men') }} </td>
                            <td class="text-right">{{resetPrice(Total)}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="row">
                    <div class="col-xl-5 col-md-6 ml-auto mr-0">
                      <table class="table ">
                        <tbody>
                          <tr>
                            <th>{{ $t('xiao-ji-1') }}</th>
                            <td class="text-right">
                              <span class="fw-600">{{resetPrice(Total)}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th>{{ $t('song-huo-di-zhi-0') }}</th>
                            <td class="text-right">
                              <span class="font-italic">{{addressInfo.Address}} {{addressInfo.City}} {{addressInfo.Country}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th>{{ $t('shui-0') }}</th>
                            <td class="text-right">
                              <span class="font-italic">{{resetPrice(0.00)}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th>{{ $t('you-hui-quan-zhe-kou-0') }}</th>
                            <td class="text-right">
                              <span class="font-italic">{{resetPrice(0.00)}}</span>
                            </td>
                          </tr>
                          <tr>
                            <th><span class="fw-600">{{ $t('zong-jin-e') }}</span></th>
                            <td class="text-right">
                                <strong><span>{{resetPrice(Total)}}</span></strong>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <Popup v-model="showAddressPopup">
      <div class="popup-box ">
        <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
          <div class="modal-content">
            <div class="modal-header bord-btm">
              <h4 class="modal-title h6">{{modalType == 'add' ? $t('New Address') : $t('Modify Address')}}</h4>
              <button type="button" class="close" @click="cancel"><span aria-hidden="true">&times;</span></button>
            </div>
            <form class="form-horizontal" >
              <div class="modal-body">
                <div class="form-group">
                  <div class=" row">
                    <label class="col-sm-3 control-label" for="address">{{ $t('di-zhi-3') }}</label>
                    <div class="col-sm-9">
                      <textarea :placeholder="$t('di-zhi-3')" v-model="addressForm.address" name="address" class="form-control" required></textarea>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class=" row">
                    <label class="col-sm-3 control-label">{{ $t('guo-jia-1') }}</label>
                    <div class="col-sm-9">
                       <a-select class="form-control clear" size="large" v-model="addressForm.country" :placeholder="$t('qing-shu-ru-guo-jia')" show-search :filter-option="filterOption">
                        <a-select-option v-for="(item, i) in countryList" :key="i" :value="item">{{item}}</a-select-option>
                      </a-select>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-3 control-label">
                      <label>{{ $t('sheng-fen-0') }}</label>
                    </div>
                    <div class="col-sm-9">
                     <input type="text" min="0" :placeholder="$t('qing-shu-ru-sheng-fen')" v-model="addressForm.province" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class="row">
                    <div class="col-sm-3 control-label">
                      <label>{{ $t('cheng-shi-1') }}</label>
                    </div>
                    <div class="col-sm-9">
                       <input type="text" min="0" :placeholder="$t('qing-shu-ru-cheng-shi')" v-model="addressForm.city" class="form-control">
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class=" row">
                    <label class="col-sm-3 control-label" for="postal_code">{{ $t('you-zheng-bian-ma-1') }}</label>
                    <div class="col-sm-9">
                      <input type="number" min="0" :placeholder="$t('you-zheng-bian-ma-1')"  v-model="addressForm.zipCode" class="form-control" required>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class=" row">
                    <label class="col-sm-3 control-label" for="postal_code">{{ $t('quan-ming') }}</label>
                    <div class="col-sm-9">
                      <input type="text" min="0" :placeholder="$t('qing-shu-ru-quan-ming')" v-model="addressForm.fullName" name="postal_code" class="form-control" required>
                    </div>
                  </div>
                </div>
                <div class="form-group">
                  <div class=" row">
                    <label class="col-sm-3 control-label" for="phone">{{ $t('dian-hua') }}</label>
                    <div class="col-sm-9">
                      <input type="number" min="0" :placeholder="$t('dian-hua')"  v-model="addressForm.phone" class="form-control" required>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary"  @click="cancel">{{ $t('qu-xiao') }}</button>
                  <button type="button" class="btn btn-primary btn-styled btn-base-1" @click="submitAddress">{{ $t('bao-cun') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>

    <Popup v-model="showPasswordPopup">
      <div class="popup-box ">
        <div class="modal-dialog modal-dialog-centered modal-dialog-zoom" role="document">
          <div class="modal-content">
            <div class="modal-header bord-btm">
              <h4 class="modal-title h6">{{ $t('jiao-yi-mi-ma-0') }}</h4>
              <button type="button" class="close" @click="cancel"><span aria-hidden="true">&times;</span></button>
            </div>
            <form class="form-horizontal" >
              <div class="modal-body">
                <div class="form-group">
                  <div class=" row">
                    <label class="col-sm-3 control-label" for="postal_code">{{ $t('jiao-yi-mi-ma-0') }}</label>
                    <div class="col-sm-9">
                      <input type="password" v-model="Password" min="0" :placeholder="$t('jiao-yi-mi-ma-0')"  class="form-control" required>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary btn-styled btn-base-1" @click="submitOrder">{{ $t('ti-jiao') }}</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Popup>
  </div>
</template>
<script>
import {  getAddressList, cardRemove, getCartList, addAddress } from '@/api/index'
import { resetPrice } from '@/utils/common'
import {  addOrder } from '@/api/user'
import { Popup, Popover } from 'vant'
import moment from 'moment'
export default {
  components: {
    Popup,
    Popover
  },
  data() {
    return {
      step: 1,
      showAddressPopup: false,
      showPasswordPopup: false,
      modalType: '',
      addressForm: {
        city: '',
        province: '',
        country: '',
        address: '',
        zipCode: '',
        phone: '',
        fullName: '',
      },
      Password:"",
      pickType: '1', // 默认送货方式
      productInfo: {},
      prodcutList: [],
      addressList: [],
      subTotal: 100.00,
      payIndex: 0,
      payList: [
        // {
        //   url: require('../../assets/imgs/cod.png'),
        //   isDefault: true,
        //   name: this.$t('huo-dao-fu-kuan')
        // },
        // {
        //   url: require('../../assets/imgs/usdt.png'),
        //   name: 'USDT-TRC20',
        //   address: 'adsfjoi12j3908dsaf08980112'
        // },
      ],
      countryList : [],
      payInfo: {},
      Total: 0,
      showInfo: false,
      couponCode: '',
      cartData: [],
      addressId: '',
      addressInfo: {},
      userMoney: '0.00',
      dataId: '', // id有值时为立即购买，不显示其他购物车数据
    }
  },
  computed: {
    cartList() {
      return this.$store.state.cartList
    },
    userInfo() {
      return this.$store.state.userInfo
    },
    tabList() {
      return  [
        {
          icon: 'la-shopping-cart',
          title: this.$t('wo-de-gou-wu-che')
        },
        {
          icon: 'la-map',
          title: this.$t('fa-huo-xin-xi')
        },
        {
          icon: 'la-truck',
          title: this.$t('jiao-huo-xin-xi')
        },
        {
          icon: 'la-credit-card',
          title: this.$t('fu-kuan')
        },
        {
          icon: 'la-check-circle',
          title: this.$t('que-ren')
        },
      ]
    }
  },
  watch: {
    cartList(val) {
      this.initListData()
    },
    userInfo(val) {
      if (val.UserAccount) {
        this.userMoney = parseFloat(this.userInfo.UserAccount.Balance).toFixed(2)
      }
    }
  },
  mounted() {
    this.init()
    this.dataId = this.$route.query.id
    this.initListData()
    this.countryList = this.$store.state.country
    if (this.userInfo.UserAccount) {
      this.userMoney = parseFloat(this.userInfo.UserAccount.Balance).toFixed(2)
    }
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    resetPrice,
    initListData() {
      if (this.dataId) {
        this.cartData = this.cartList.filter(v => {
          return v.GoodsId == this.dataId && v.Attribute == 1
        }).map(v => {
          return Object.assign({count: 1}, v)
        })
      } else {
        this.cartData = this.cartList.map(v => {
          return Object.assign({count: 1}, v)
        })
      }
    },
    init() {
      getAddressList().then(res => {
        this.addressList = res.data.Items
        let index = this.addressList.findIndex(v => {
          return v.IsSelected == 1
        })
        if (index > -1) {
          this.addressId = this.addressList[index].ID
          this.addressInfo = this.addressList[index]
        }
      })
    },
    // 获取规格价格
    skuPrice(item){
      try {
        let SaleSpecifications = JSON.parse(item.Goods.SaleSpecifications)
        let SkuPropId = item.SkuPropId
        if(SaleSpecifications.price === undefined) {
          return item.Goods.Price
        }
        for(var i in SaleSpecifications.price){
          let price = SaleSpecifications.price[i]
          if(price.skuPropIds == SkuPropId){
            return price.skuVal.skuAmount.value
          }
        }
      } catch (e) {
        return item.Goods.Price
      }
    },
    minusCount(data, i) {
      if (data.count <= 1) {
        return
      }
      let count = data.count - 1
      this.$set(this.cartData[i], 'count', count)
    },
    plusCount(data, i) {
      let max = data.Goods.Stock || 9999
      if (data.count >= max) {
        return
      }
      let count = data.count + 1
      this.$set(this.cartData[i], 'count', parseInt(count))
    },
    doSubmit() {
      if (this.step == 1) {
        this.step = 2
      } else if (this.step == 2) {
        this.step = 3
      } else if (this.step == 3) {
        this.step = 4
      } else if (this.step == 4) {
        this.showPasswordPopup = true
      } else if (step == 5) {
        
      }
    },
    submitOrder() {
      this.showPasswordPopup = false
      let obj = {}
      let skuList = {}
      this.cartData.forEach(v => {
        obj[v.Goods.ID] = v.count
        skuList[v.Goods.ID] = v.SkuPropId
      })
      addOrder({
        "ProductID": obj,
        "Sku": skuList,
        "DeliveryAddressID": this.addressId,
        "Password": this.Password,
        "PayType": this.payIndex == 0 ? 1 : 2
      }).then(res => {
        this.Password = ''
        if (res.code == 0) {
          this.step = 5
          this.Total = this.getSum()
          this.cartData.forEach(v => {
            this.removeGoods(v)
          })
          this.initCart()
        } else {
          this.$toast.fail(res.msg)
        }
      })
    },
    cancel() {
      this.showAddressPopup = false
      this.showPasswordPopup = false
    },
    addAddress() {
      this.showAddressPopup = true
      this.modalType = 'add'
    },
    changePickType(type) {
      this.pickType = type
    },
    changeType(i) {
      this.payIndex = i
      if (i == 0) {
        this.showInfo = false
      } else {
        this.showInfo = true
        this.payInfo = this.payList[i]
      }
    },
    getTotal(data) {
      let count = data.count || 1
      let total = parseFloat(parseFloat(this.skuPrice(data)) * count).toFixed(2)
      return total
    },
    testGetSum(){
      debugger
      this.getSum()
    },
    getSum() {
      let sum = 0
      this.cartData.forEach(v => {
        let count = v.count || 1
        let total = parseFloat(this.skuPrice(v)) * count
        sum += total
      })
      return sum.toFixed(2)
    },
    removeGoods(data) {
      let form = new FormData()
			form.append('ID', data.ID)
			cardRemove(form).then(res => {
				if (res.code == 0) {
					// this.$toast(this.$t('shan-chu-cheng-gong'))
          // 清除购物车
					this.initCart()
				} else {
					this.$toast.fail(res.msg)
				}
			})
    },
    initCart() {
      let form = new FormData()
      form.append('current', 1)
      form.append('pageSize', 100)
      getCartList(form).then(res => {
				this.$store.state.cartList = res.data.Items.filter(v => {
          return v.Attribute == 1
        })
				this.$store.state.wishList = res.data.Items.filter(v => {
          return v.Attribute == 2
        })
			})
		},
    changeAddress(data) {
      this.addressId = data.ID
      this.addressInfo = data
    },
    checkMoney() {
      let total = this.getSum()
      return parseFloat(this.userMoney) < parseFloat(total)
    },
    getTime() {
      return moment().format('YYYY-MM-DD HH:mm:ss')
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    submitAddress() {
      if (!this.addressForm.address) {
        this.$toast.fail(this.$t('qing-shu-ru-di-zhi'))
        return
      }
      if (!this.addressForm.country) {
        this.$toast.fail(this.$t('qing-shu-ru-guo-jia-0'))
        return
      }
      if (!this.addressForm.province) {
        this.$toast.fail(this.$t('qing-shu-ru-sheng-fen-0'))
        return
      }
      if (!this.addressForm.city) {
        this.$toast.fail(this.$t('qing-shu-ru-cheng-shi-0'))
        return
      }
      if (!this.addressForm.zipCode) {
        this.$toast.fail(this.$t('qing-shu-ru-you-zheng-bian-ma'))
        return
      }
      if (!this.addressForm.fullName) {
        this.$toast.fail(this.$t('qing-shu-ru-quan-ming'))
        return
      }
      if (!this.addressForm.phone) {
        this.$toast.fail(this.$t('qing-shu-ru-dian-hua'))
        return
      }
      let form = new FormData()
      form.append('FullName', this.addressForm.fullName)
      form.append('Country', this.addressForm.country)
      form.append('Province', this.addressForm.province)
      form.append('City', this.addressForm.city)
      form.append('Address', this.addressForm.address)
      form.append('ZipCode', this.addressForm.zipCode)
      form.append('Phone', this.addressForm.phone)
      addAddress(form).then(res => {
        if (res.code == 0) {
          this.$toast.success(this.$t('tian-jia-cheng-gong'))
          this.showAddressPopup = false
          this.init()
        } else {
          this.$toast.fail(res.msg)
        }
      })

    },
  }
}
</script>